.reportserchbtn {
  width: 80px;
  height: 30px;
  gap: 0px;
  border-radius: 5px;
  opacity: 0px;
  background: #50a49a;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  border: none;
}


.reportserchbtn:hover{
    width: 80px;
    height: 30px;
    gap: 0px;
    border-radius: 5px;
    opacity: 0px;
    background: #50a49a;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
    border: none;
}

.reportinputselect{
  font-family: Poppins;
font-size: 13px;
font-weight: 400;
line-height: 19.5px;
text-align: left;
color: #000000;
align-items: center;
}