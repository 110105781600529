.addphotobtn {
  width: 150px;
  height: 37px;
  border-radius: 4px;
  background: #1976d2;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.notificationdatetime {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.previewcheck {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #212943;
}

.datetimelogin {
  width: 201.5px;
  height: 37px;
  left: 112px;
  border-radius: 5px;
  border: 1px solid #000000;
}

.datetimebtn {
  width: 74px;
  height: 37px;
  border-radius: 4px;
  background: #e27531;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.datetimebtn:hover {
  background: #e27531;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.targethead {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.notificationlabel {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #212943;
}

.notificationsave {
  width: 74px;
  height: 37px;
  gap: 0px;
  border-radius: 4px;
  opacity: 1px;
  background: #1976d2;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}
.notificationsave:hover {
  background: #1976d2;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.notificationdelete {
  width: 74px;
  height: 37px;
  gap: 0px;
  border-radius: 4px;
  opacity: 1px;
  background: #9b9b9b;
  border: none;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.notificationdelete:hover {
  background: #9b9b9b;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.notificationconf {
  width: 74px;
  height: 37px;
  gap: 0px;
  border-radius: 4px;
  opacity: 1px;
  background: #50a49a;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.notificationconf:hover {
  background: #50a49a;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.testbtn {
  width: 74px;
  height: 37px;
  border-radius: 4px;
  background: #e27531;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.testbtn:hover {
  background: #e27531;
  color: #ffffff;
}
