.custom-shadow {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.search-heading {
  font-family: "poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.custom-select {
  font-family: "poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #8b8b8b;
  border-radius: 7px;
}

.custom-search-member {
  font-family: "poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #8b8b8b;
  border-radius: 7px;
}

.member-result {
  font-family: "poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.custom-th {
  font-family: "poppins";
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
}

.custom-td {
  font-family: "poppins";
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
}

.centered-table th,
.centered-table td {
  /* text-align: center; */
  vertical-align: middle;
}

.custom-height {
  height: 29px;
}

.identitysearchbtn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #1a73e8;
  background: #1a73e8;
}
.identityreset {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #1a73e8;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a73e8;
}