.fileheading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.filebtn {
  width: 150px;
  height: 37px;
  top: 186px;
  left: 731px;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  background: #50a49a;
  border: none;
}

.filebtn:hover {
  width: 150px;
  height: 37px;
  top: 186px;
  left: 731px;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  background: #50a49a;
  border: none;
}
