.basicheading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.targetheading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.publictext {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #848484;
}

.consetsave {
  width: 83px;
  height: 30px;
  border-radius: 5px;
  background-color: #1a73e8;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
