.instructorphoto {
  background: #1976d2;
}

.instructorphotoss {
  background: #1976d2;
  color: #ffffff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  height: 37px;
}

.instructorphotoss:hover {
  background: #1976d2;
  color: #ffffff;
}
