 /* .user-add-cl {
  background-color: #50a49a;
  border-color: #50a49a;
  width: 80px;
  height: 30px;
  font-family: "poppins";
  font-weight: 400;
  line-height: 19.5px;
  align-items: center;
}



.user-add-cl:hover {
  background-color: #50a49a;
  border-color: #50a49a;
}

.user-delete-cl {
  background-color: #ff4694;
  border-color: #ff4694;
  width: 80px;
  height: 30px;
  font-family: "poppins";
  font-weight: 400;
  line-height: 19.5px;
  align-items: center;
}

.user-delete-cl:hover {
  background-color: #ff4694;
  border-color: #ff4694;
}

.user-title {
  font-family: "poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

.role-management-table {
  border-bottom: 1px solid #848484;
  align-items: center;
  width: 1100px;
}

.role-management-table th {
  font-family: "poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  height: 70px;
}

.role-management-table td {
  font-family: "poppins";
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  height: 70px;
}

.role-management-table tbody td {
  vertical-align: middle;
}

.role-management-table th:nth-child(2),
.role-management-table td:nth-child(2) {
  padding-left: 700px;
} 




.user-management-container {
  position: relative;
  left: 70px;
}

.user-title {
  font-size: 24px;
  font-weight: bold;
}

.user-add-cl {
  background-color: #007bff;
  color: #fff;
}

.user-delete-cl {
  background-color: #dc3545;
  color: #fff;
}

.role-management-table th, .role-management-table td {
  border-bottom: 1px solid #848484;
}

.modal-header {
  font-family: Poppins;
}

.modal-body {
  position: relative;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
} */


.MuiTablePagination-toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-right: 8px;
}

.MuiTablePagination-select {
  margin-left: 4px;
}




.custom-success-btn1 {
  background-color: #50a49a;
  border-color: #50a49a;
  width: 150px;
  height: 30px;
  margin: 5px;
  gap: 0px;
  border-radius: 5px;
  opacity: 1;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
}

.custom-success-btn1:hover {
  background-color: #50a49a;
  border-color: #50a49a;
}
