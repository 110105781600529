.btn.advancefilter {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #1a73e8;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a73e8;
  background: #ffffff;
}

.btn.advancefilter:hover {
  border: 1px solid #1a73e8;
  color: #1a73e8;
  background: #ffffff;
}

.advanceinputlabel {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #212943;
}

.advanceinput {
  height: 29px;
  border-radius: 7px;
  border: 1px solid #8b8b8b;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
