.enrollementhead {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.modaldialogss {
  /* max-width: 85%;
   */
  max-width: 80% !important;
}

.groupheading {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}

.grouplabel {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}

.groupclear {
  width: 97px;
  height: 34px;
  border-radius: 2px;
  opacity: 0px;
  background: #448ac0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.groupclear:hover {
  background: #448ac0;
  color: #ffffff;
}

.groupsearch {
  width: 97px;
  height: 34px;
  border-radius: 2px;
  background: #e27531;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.groupsearch:hover {
  color: #ffffff;
  background: #e27531;
}

.tableheadgrp {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}

.custom-modal-width .modal-dialog {
  max-width: 30%;
}

.memberheads {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}

.memberslabels {
  height: 30px;
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
}

.custom-modal-group .modal-dialog {
  max-width: 85% !important; /* Ensures the width is 85% of the screen */
  width: 85% !important; /* Ensures the modal width is 85% */
  margin: 0 auto; /* Centers the modal horizontally */
}

.canslebtndialog {
  width: 97px;
  height: 34px;
  border-radius: 5px;
  background: #8b8b8b;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.canslebtndialog:hover {
  background: #8b8b8b;
  color: #ffffff;
}

.logoutdialogbtn {
  width: 97px;
  height: 34px;
  border-radius: 5px;
  background: #1a73e8;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.logoutdialogbtn:hover {
  background: #1a73e8;
  color: #ffffff;
}
