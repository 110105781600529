.attendancebtn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  background: #50a49a;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.attendancebtn:hover {
  color: #ffffff;
  background: #50a49a;
}

.lessoncanslebtn {
  width: 74px;
  height: 37px;
  border-radius: 4px;
  border: 1px solid #1976d2;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #1976d2;
}

.lessoncanslebtn:hover {
  border: 1px solid #1976d2;

  color: #1976d2;
}

.lessonsavebtn {
  width: 110px;
  height: 37px;
  border-radius: 4px;
  background: #9b9b9b;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.lessonsavebtn:hover {
  color: #ffffff;
  background: #9b9b9b;
}

.lessonapprovelbtn {
  height: 37px;
  border-radius: 4px;
  background: #9b9b9b;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.lessonapprovelbtn:hover {
  color: #ffffff;
  background: #9b9b9b;
}

.lessonaddbtn {
  height: 37px;
  border-radius: 4px;
  opacity: 0px;
  background: #50a49a;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.lessonaddbtn:hover {
  background: #50a49a;
  color: #ffffff;
}

.lessonheading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #212943;
}

.lessoncard {
  background: #50a49a;
  height: 37px;
}

.cardtext {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}



.custom-modal-attendance .modal-dialog {
  max-width: 80% !important; /* Ensures the width is 85% of the screen */
  width: 80% !important; /* Ensures the modal width is 85% */
  margin: 0 auto; /* Centers the modal horizontally */
}