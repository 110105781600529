.surveyheading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.usercomment {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.usertext{
    font-family: Poppins;
font-size: 15px;
font-weight: 400;
line-height: 22.5px;
text-align: left;
color: #848484;

}