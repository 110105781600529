.basicinfo {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    color: #212943;
  }
  
  .basicinfolabel {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    color: #212943;
  }
  
  .basicinfoinput{
    border: 1px solid #000000
    ;
    height: 53px;
  }