.usercategorieshead {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #000000;
}

.userlabel {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #000000;
}

.userinput {
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #d9d9d9;
}

.useraddbtn {
  width: 80px;
  height: 30px;
  border-radius: 5px;
  /* opacity: 0px; */
  /* margin-left: 350px; */
  background: #50a49a;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.useraddbtn:hover {
  background: #50a49a;
}

.custom-select {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #000000;
}

.custom-th {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #000000;
  height: 40px;
}

.custom-td {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #000000;
  height: 40px;
}

/* .custom-tdtd {
  text-align: right;
} */

.useraddbtnbtn {
  width: 80px;
  height: 30px;
  /* gap: 0px; */
  border-radius: 5px;
  /* opacity: 1px; */
  background: #50a49a;

  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.useraddbtnbtn:hover {
  background: #50a49a;
}

.userdelete {
  width: 80px;
  height: 30px;
  /* gap: 0px; */
  border-radius: 5px;
  /* opacity: 1px; */
  background: #ff4694;

  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.userdelete:hover {
  background: #ff4694;
}

.userformcansle {
  width: 97px;
  height: 34px;
  gap: 0px;
  border-radius: 2px 0px 0px 0px;
  /* opacity: 0px; */
  background: #448ac0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.userformsave {
  width: 97px;
  height: 34px;
  gap: 0px;
  border-radius: 2px 0px 0px 0px;
  /* opacity: 0px; */
  background: #e27531;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  border: none;
}

.usercategoriesformhead {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #000000;
}
