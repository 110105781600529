.volunteertext {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #212943;
}

.volunteersave {
  height: 37px;
  border-radius: 4px;
  border: 1px solid #1a73e8;
  background: #1a73e8;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.volunteercansle {
  height: 37px;
  border-radius: 4px;
  border: 1px solid #1a73e8;
  background: #ffffff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a73e8;
}

.volunteercansle:hover {
  height: 37px;
  border-radius: 4px;
  border: 1px solid #1a73e8;
  background: #ffffff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a73e8;
}
