.container-fluid,
.row,
.col-md-4 {
  padding: 0;
  margin: 0;
  position: relative;
}

.clickable-item {
  margin: 0;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19.5px;
  padding-left: 10px;
  list-style-type: none;
  width: 100%;
}

.clickable-item .custom-button {
  color: black;
  text-decoration: none;
  width: 100%;
  text-align: left;
  padding: 0;
}

.clickable-item.active {
  background: linear-gradient(to right, #2c80cf 6px, #deefff 6px 100%);

  width: 100%;
}

.clickable-item.active .custom-button {
  color: #2c80cf;
}

ul li::marker {
  font-size: 20px;
  color: blue;
}
.table-header {
  color: #212943;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  background-color: #e9e9e9;
}
.titlename {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #212943;
}

.instructorphotoss {
  background: #1976d2;
  color: #ffffff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  height: 37px;
}

.instructorphotoss:hover {
  background: #1976d2;
  color: #ffffff;
}

.canslebtn {
  height: 37px;
  border-radius: 4px;
  border: 1px solid #1976d2;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #1976d2;
}

.canslebtn:hover {
  color: #1976d2;
  background: #ffffff;
}

.deletebtn {
  height: 37px;
  border-radius: 4px;
  border: 1px solid;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
  background: #ee0505;
}
.deletebtn:hover {
  color: #ffffff;
  background: #ee0505;
}

/* MemberInfo.css */
input::-ms-reveal {
  display: none;
}

input::-ms-clear {
  display: none;
}
