.basicheading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.targetheading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #212943;
}

.publictext {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #848484;
}

.basicsavebtn {
  width: 83px;
  height: 30px;
  border-radius: 5px;
  background: #50a49a;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  border: none;
}

.basicsavebtn:hover {
  width: 83px;
  height: 30px;
  border-radius: 5px;
  background: #50a49a;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.basiccancelbtnn {
  width: 83px;
  height: 30px;
  border-radius: 5px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a73e8;
  border: 1px solid #1a73e8;
}
.basiccancelbtnn:hover {
  width: 83px;
  height: 30px;
  border-radius: 5px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1a73e8;
  border: 1px solid #1a73e8;
}

.basicinfocard {
  height: 87px;
  border-radius: 5px;
  background: #ecf4ff;
  border: none;
}

.basicstatus {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Main, #212943);
}

.assigntohead {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--Main, #212943);
}

.assigntocheck {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #212943;
}
