.checkbox-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.checkbox-group .form-check {
  margin-right: 10px;
}

.lablecheck {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}

.checkbtn {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}

.maintitle {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.floating-label-group {
  position: relative;
  margin-top: 1rem;
}

.floating-input {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  height: 53px;
  border: 1px solid black;
  border-radius: 0.25rem;
}

.floating-label {
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  font-size: 0.85rem;
  color: #6c757d;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  background: white;
  padding: 0 0.25rem;
}

.floating-input:focus ~ .floating-label,
.floating-input:not(:placeholder-shown) ~ .floating-label {
  top: -0.5rem;
  left: 0.75rem;
  color: #212943;
  background: white;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #212943;
}

.custom-modal .btn-secondary {
  background-color: #448ac0;
  border-color: #448ac0;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: white;
  width: 97px;
  height: 34px;
  border-radius: 0%;
}

.custom-modal .btn-secondary:hover,
.custom-modal .btn-secondary:focus {
  background-color: #357ab9;
  border-color: #357ab9;
}

.custom-modal .btn-primary {
  background-color: #e27531;
  border-color: #e27531;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  width: 97px;
  height: 34px;
  border-radius: 0%;
  color: white;
}

.custom-modal .btn-primary:hover,
.custom-modal .btn-primary:focus {
  background-color: #d4621d;
  border-color: #d4621d;
}

.form-check-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  border: 1px solid #d9d9d9;
  border-radius: 0.25em;
  background-color: #ffffff;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #d9d9d9;
}

.form-check-input:checked::after {
  content: "";
  position: absolute;
  top: 0.08em;
  left: 0.32em;
  width: 0.37em;
  height: 0.7em;
  border: solid #212943;
  border-width: 0 0.18em 0.18em 0;
  transform: rotate(45deg);
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-label {
  vertical-align: middle;
  margin-left: 0.3em;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.success-popup {
  position: fixed;
  top: 20%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 500px;
}

.rolecancelbtn {
  width: 97px;
  height: 34px;
  border-radius: 2px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  background: #8B8B8B;

}

.rolecancelbtn:hover {
  width: 97px;
  height: 34px;
  background: #8B8B8B;

  border-radius: 2px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.rolesavebtn {
  width: 97px;
  height: 34px;
  border-radius: 2px;
  background: #1a73e8;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.rolesavebtn:hover {
  width: 97px;
  height: 34px;
  border-radius: 2px;
  background: #1a73e8;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
