.notificationinputname {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #000000;
}

.customnotificationbtn {
  background: #50a49a;
  border: none;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: #ffffff;
}

.customnotificationbtn:hover{
    background: #50a49a;
    border: none;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
    color: #ffffff;
}