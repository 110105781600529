.custom-danger-btn {
  background-color: #ff4694;
  border-color: #ff4694;
  width: 80px;
  height: 30px;
  gap: 0px;
  border-radius: 5px;
  opacity: 1;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  margin: 5px;
}

.custom-danger-btn:hover {
  background-color: #ff4694;
  border-color: #ff4694;
}

.custom-success-btn {
  background-color: #50a49a;
  border-color: #50a49a;
  width: 80px;
  height: 30px;
  margin: 5px;
  gap: 0px;
  border-radius: 5px;
  opacity: 1;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
}

.custom-success-btn:hover {
  background-color: #50a49a;
  border-color: #50a49a;
}

.firstname {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.tabaleh {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.role {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}

.role-management-table {
  background-color: transparent;
}

.role-management-table th,
.role-management-table td {
  background-color: transparent;
}

.role-management-table {
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px solid #848484;
}




/* Custom CSS for active page number */
.pagination .page-item .page-link.bg-success {
  background-color: #50a49a !important; /* Darker green */
  color: white;
  border: 1px solid  #50a49a; /* Optional border for better visibility */
}

/* Green text for non-active page numbers */
.pagination .page-item .page-link.text-success {
  color: #50a49a !important;
}

/* Green text for Next and Previous buttons */
.pagination .page-item .page-link.text-success {
  color: #50a49a !important;
}

/* Disabled buttons */
.pagination .page-item .page-link:disabled {
  background-color: #f8f9fa;
  color: #6c757d;
}
